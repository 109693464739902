import React from 'react';

import Icon from './Icon';

export default function StrokeDiscoverNewDocuments({
  className = '',
  text = 'Add',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <path id="Path (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H16C16.4142 13.25 16.75 13.5858 16.75 14C16.75 14.4142 16.4142 14.75 16 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="currentColor" />
          <path id="Path (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H16C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z" fill="currentColor" />
          <path id="Rectangle (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M2.25 5C2.25 3.48122 3.48122 2.25 5 2.25H19C20.5188 2.25 21.75 3.48122 21.75 5V19C21.75 20.5188 20.5188 21.75 19 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V5ZM5 3.75C4.30964 3.75 3.75 4.30964 3.75 5V19C3.75 19.6904 4.30964 20.25 5 20.25H19C19.6904 20.25 20.25 19.6904 20.25 19V5C20.25 4.30964 19.6904 3.75 19 3.75H5Z" fill="currentColor" />
          <path id="Path (Stroke)_3" fillRule="evenodd" clipRule="evenodd" d="M16.6241 7.58405C16.8539 7.9287 16.7608 8.39435 16.4161 8.62412L13.4161 10.6241C13.1186 10.8224 12.7225 10.7832 12.4697 10.5304L10.9045 8.96518L8.4161 10.6241C8.07146 10.8539 7.60581 10.7608 7.37604 10.4161C7.14628 10.0715 7.23941 9.60581 7.58405 9.37604L10.5841 7.37604C10.8815 7.17773 11.2776 7.21695 11.5304 7.46975L13.0956 9.03498L15.5841 7.37604C15.9287 7.14628 16.3944 7.23941 16.6241 7.58405Z" fill="currentColor" />
        </g>
      </svg>
    </Icon>
  );
}
