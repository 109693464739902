import React from 'react';

import Icon from './Icon';

export default function IntegrationsIcon({
  className,
  text = 'integrations',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12.9697 5.96967C13.2626 5.67678 13.7375 5.67678 14.0304 5.96967L17.5304 9.46967C17.7449 9.68417 17.809 10.0068 17.6929 10.287C17.5768 10.5673 17.3034 10.75 17 10.75H7.00002C6.58581 10.75 6.25002 10.4142 6.25002 10C6.25002 9.58579 6.58581 9.25 7.00002 9.25H15.1894L12.9697 7.03033C12.6768 6.73744 12.6768 6.26256 12.9697 5.96967Z" fill="currentColor" />
        <path d="M6.30711 13.713C6.4232 13.4327 6.69668 13.25 7.00002 13.25H17C17.4142 13.25 17.75 13.5858 17.75 14C17.75 14.4142 17.4142 14.75 17 14.75H8.81068L11.0304 16.9697C11.3232 17.2626 11.3232 17.7374 11.0304 18.0303C10.7375 18.3232 10.2626 18.3232 9.96969 18.0303L6.46969 14.5303C6.25519 14.3158 6.19103 13.9932 6.30711 13.713Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 5C2.25 3.48122 3.48122 2.25 5 2.25H19C20.5188 2.25 21.75 3.48122 21.75 5V19C21.75 20.5188 20.5188 21.75 19 21.75H5C3.48122 21.75 2.25 20.5188 2.25 19V5ZM5 3.75C4.30964 3.75 3.75 4.30964 3.75 5V19C3.75 19.6904 4.30964 20.25 5 20.25H19C19.6904 20.25 20.25 19.6904 20.25 19V5C20.25 4.30964 19.6904 3.75 19 3.75H5Z" fill="currentColor" />
      </svg>
    </Icon>
  );
}
